<template>
  <div class="flex flex-col gap-y-[16px]">
    <UICheckbox
      label="Trigger on enter"
      size="medium"
      ref="triggersEnter"
      v-model="triggersData.enter"
    />
    <UICheckbox
      label="Trigger on exit"
      size="medium"
      ref="triggersExit"
      v-model="triggersData.exit"
    />
  </div>
  <div class="flex flex-col gap-y-[24px]">
    <UICombobox
      label="Geofence"
      placeholder="Enter geofence name"
      ref="geofenceSelect"
      :options="items"
      :open-on-focus="true"
      v-model="selectedAddress"
    />
    <!-- <div class="h-[320px]" /> -->
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'GeofenceField',
  emits: ['onChange'],
  props: {
    triggers: {
      type: [Array],
      required: true,
    },
    address: {
      type: [Number],
      required: true,
    },
  },
  data() {
    return {
      triggersData: {
        enter: false,
        exit: false,
      },
      selectedAddress: {},
    };
  },
  computed: {
    ...mapGetters({
      geofences: 'geofence/all',
    }),
    items() {
      return this.geofences.map((geofence) => ({
        label: geofence.name,
        value: geofence.id,
      }));
    },
  },
  mounted() {
    if (this.triggers?.length) {
      this.$refs.triggersEnter.setCheckQuietly(
        this.triggers?.includes('enter'),
      );
      this.$refs.triggersExit.setCheckQuietly(this.triggers?.includes('exit'));
    }

    if (this.address) {
      this.$nextTick(() => {
        this.populateGeofenceSelect();
      });
    }
  },
  watch: {
    triggersData: {
      deep: true,
      handler() {
        this.onChange();
      },
    },
    selectedAddress() {
      this.onChange();
    },
    items: {
      handler() {
        this.populateGeofenceSelect();
      },
    },
  },
  methods: {
    populateGeofenceSelect() {
      if (!this.items?.length) {
        return;
      }
      const item = this.items.find(
        (geofence) => geofence.value === this.address,
      );

      if (!item) {
        return;
      }

      this.$refs.geofenceSelect?.setSelectedOptionQueitly(item);
    },
    onChange() {
      const triggers = [];
      if (this.triggersData.enter) {
        triggers.push('enter');
      }

      if (this.triggersData.exit) {
        triggers.push('exit');
      }

      this.$emit('onChange', {
        triggers,
        address: this.selectedAddress?.value,
      });
    },
  },
};
</script>
