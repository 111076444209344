import { defineStore } from 'pinia';
import service from '@/services';
import { setAbortController } from '@/composables/useAbortController';
import type { Position } from '@/domain.ts';
import { ref } from 'vue';

export const usePosition = defineStore('position', () => {
  const isLoading = ref(false);
  const positions = ref(new Map<number, any>());

  const fetchPositionsByAccount = async (accountId: number) => {
    isLoading.value = true;
    const controller = setAbortController('positions');

    const response = await service.get(
      `/accounts/${accountId}/current-positions?raw=1`,
      {
        signal: controller.signal,
      },
    );

    if ((response && response.status === 499) || !response?.data?.data) {
      isLoading.value = false;
      return response;
    }

    positions.value.clear();
    for (const position of response.data.data) {
      positions.value.set(position.device_id, position);
    }

    isLoading.value = false;

    return response;
  };

  const getPositionByDeviceId = (deviceId: number): Position | null => {
    return positions.value.get(deviceId);
  };

  const getAllPositions = (): Map<number, Position> => {
    return positions.value;
  };

  const addPosition = (position: Position): Position => {
    const exist = positions.value.has(position.device_id);
    if (!exist) {
      positions.value.set(position.device_id, position);
    }
    return position;
  };

  return {
    isLoading,
    positions,

    fetchPositionsByAccount,
    getPositionByDeviceId,
    getAllPositions,
    addPosition,
  };
});
