<template>
  <ModalLayout title="Order Payment" large>
    <div class="rounded-t-[10px] bg-white pt-[41px]">
      <div
        @click.prevent="close"
        class="absolute right-[24px] top-[36px] flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-full border border-gpx_gray-800 sm:right-[40px]"
      >
        <XMarkIcon
          class="heroicon-stroke-w-1.4 h-[20px] w-[20px] text-gpx_gray-200"
        />
      </div>
      <div
        class="mx-[24px] border-b border-b-gpx_gray-800 pb-[18px] sm:mx-[40px]"
      >
        <div class="mb-[2px] text-gpx_sm font-medium text-gpx_gray-200">
          Payment details
        </div>
        <div class="text-gpx_2xl font-semibold text-lms_black-500">
          Order #{{ order.id }}
        </div>
      </div>
      <div v-if="isB2BAccount" class="mt-[36px] px-[40px]">
        <UITabGroup class="w-full" :tabs="tabs" v-model="tabIndex" />
      </div>
      <div v-show="tabIndex === 0" class="mt-[26px]">
        <div
          v-if="isLoadingPaymentMethods"
          class="flex items-center justify-center px-[24px] pb-3 sm:px-[40px]"
        >
          <UISpinner size="sm" class="mr-2" />
          <span class="text-xs text-gpx_gray-100">
            Payment system initialization...
          </span>
        </div>
        <div v-if="!isLoadingPaymentMethods">
          <div class="flex items-start justify-between px-[24px] sm:px-[40px]">
            <div class="flex flex-col gap-y-[3px]">
              <div class="text-gpx_base font-semibold text-lms_black-400">
                Card Details
              </div>
              <div class="text-gpx_sm text-gpx_gray-100">
                Enter your payment details
              </div>
            </div>
            <div class="flex items-center" v-if="hasPaymentMethods">
              <span class="mr-3 text-sm font-medium text-lms_black-100">
                Use default
              </span>
              <UIToggle v-model="isUsingExistingPaymentMethod" />
            </div>
          </div>
          <div
            v-if="isUsingExistingPaymentMethod"
            class="mt-[22px] flex w-full flex-col px-[24px] sm:px-[40px]"
          >
            <UISelect
              placeholder="Select your card"
              :options="paymentMethodsOptions"
              :current="selectedPaymentMethod"
              no-caret
              @onSelect="onPaymentMethodSelect"
            />
          </div>
          <template v-if="!isUsingExistingPaymentMethod">
            <div
              v-if="!isStripeLoaded"
              class="flex justify-center px-[24px] sm:px-[40px]"
            >
              <UISpinner size="sm" class="pt-4" />
            </div>
            <div
              id="payment-form"
              class="mt-[22px] flex w-full flex-col px-[24px] sm:px-[40px]"
            />
          </template>
        </div>
      </div>
      <div v-if="tabIndex === 1" class="mt-[26px]">
        <div class="flex flex-col gap-y-[3px] sm:px-[40px]">
          <div class="text-gpx_base font-semibold text-lms_black-400">
            Bank Details
          </div>
          <div class="text-gpx_sm text-gpx_gray-100">
            Enter your payment details
          </div>
        </div>

        <div
          class="mt-[22px] flex flex-col gap-y-[27px] px-[24px] sm:px-[40px]"
        >
          <UIInput
            v-model="achRoutingNumber.value"
            :hasError="achRoutingNumber.hasError"
            label="Bank Routing Number"
          />
          <UIInput
            v-model="achAccountNumber.value"
            :hasError="achAccountNumber.hasError"
            label="Account Number"
          />
        </div>
      </div>

      <div class="mt-[27px] px-[24px] pt-[1px] sm:px-[40px]">
        <UICheckbox
          :label="acceptanceText"
          :value="acceptance.value"
          v-model="acceptance.value"
        />
      </div>
    </div>
    <div
      class="flex gap-x-4 rounded-b-[10px] bg-white px-[24px] py-[32px] sm:px-[40px]"
    >
      <UIButton
        type="outline"
        class="w-full"
        :label="goBackAttrs.label"
        @click="goBackAttrs.onClick"
      />
      <UIButton
        class="w-full"
        :loading="isPaymentInProgress"
        :disabled="isSubmitDisabled"
        :label="isPaymentInProgress ? 'Processing...' : 'Submit Payment'"
        @click="handleSubmitPayment"
      />
    </div>
  </ModalLayout>
</template>

<script>
import * as _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { useToast } from 'vue-toastification';
import { VITE_APP_STRIPE_KEY } from '@/config/environment';
import ModalLayout from '@/layouts/ModalLayout.vue';
import { formatMoney } from '@/filters';
import { getCardLabel, getStripeElementsAppearance } from '@/utils/payment';

const toast = useToast();

export default {
  components: {
    XMarkIcon,
    ModalLayout,
  },

  props: {
    params: Object,
  },

  data() {
    return {
      tabs: ['Credit Card', 'ACH'],
      tabIndex: 0,
      isLoadingPaymentMethods: true,
      isUsingExistingPaymentMethod: false,
      selectedPaymentMethod: null,
      isStripeLoaded: false,
      stripe: null,
      stripeElements: null,
      isPaymentInProgress: false,
      achAccountNumber: {
        value: '',
        hasError: false,
      },
      achRoutingNumber: {
        value: '',
        hasError: false,
      },
      acceptance: {
        value: true,
        hasError: false,
      },
    };
  },

  computed: {
    ...mapGetters({
      accountId: 'auth/accountId',
      isB2BAccount: 'auth/isB2B',
      paymentMethods: 'paymentMethod/all',
    }),
    order() {
      return this.params.order;
    },
    upfrontPayment() {
      return this.params.upfrontPayment;
    },
    monthlyPayment() {
      return this.params.monthlyPayment;
    },
    monthlyPaymentsNumber() {
      return this.params.monthlyPaymentsNumber;
    },
    nextPayment() {
      return this.upfrontPayment || this.monthlyPayment;
    },
    acceptanceText() {
      let text = 'I agree to be charged ';
      if (this.upfrontPayment) {
        text += `$${formatMoney(this.upfrontPayment.amount)} up front`;
        if (this.monthlyPayment) text += ' and an additional ';
      }
      if (this.monthlyPayment) {
        text += `${
          this.monthlyPaymentsNumber
        } monthly payments of $${formatMoney(this.monthlyPayment.amount)}.`;
      }
      return text;
    },
    goBackAttrs() {
      return this.params.goBackAttrs;
    },
    redirectAttrs() {
      return this.params.redirectAttrs;
    },
    hasPaymentMethods() {
      return Boolean(this.paymentMethods.length);
    },
    paymentMethodsOptions() {
      return this.hasPaymentMethods
        ? this.paymentMethods.map((item) => ({
            ...item,
            label: getCardLabel(item),
          }))
        : [];
    },
    isSubmitDisabled() {
      return (
        !this.acceptance.value ||
        this.isLoadingPaymentMethods ||
        this.isPaymentInProgress
      );
    },
  },

  watch: {
    isUsingExistingPaymentMethod(n) {
      if (!this.isLoadingPaymentMethods && !n) {
        this.initStripePaymentForm();
      }
    },
  },

  mounted() {
    this.init();
  },

  methods: {
    ...mapActions({
      getPaymentIntent: 'orders/getPaymentIntent',
      requestACHPayment: 'orders/requestACHPayment',
      fetchPaymentMethods: 'paymentMethod/fetch',
    }),
    init() {
      this.stripe = window.Stripe(VITE_APP_STRIPE_KEY);
      this.fetchPaymentMethods(this.accountId)
        .then((res) => {
          if (res.data.data.length) {
            this.isUsingExistingPaymentMethod = true;
            this.selectDefaultPaymentMethodOption();
          } else {
            this.isUsingExistingPaymentMethod = false;
            this.initStripePaymentForm();
          }
        })
        .finally(() => (this.isLoadingPaymentMethods = false));
    },
    initStripePaymentForm() {
      this.getPaymentIntent(this.order.id).then((paymentIntent) => {
        this.stripeElements = this.stripe.elements({
          clientSecret: paymentIntent.client_secret,
          appearance: getStripeElementsAppearance(),
        });
        this.paymentElement = this.stripeElements.create('payment');
        this.paymentElement.mount('#payment-form');
        this.isStripeLoaded = true;
      });
    },
    onPaymentMethodSelect(item) {
      this.selectedPaymentMethod = item;
    },
    handleSubmitPayment() {
      if (!this.acceptance.value) {
        this.acceptance.hasError = true;
        return;
      }
      this.acceptance.hasError = false;

      if (this.tabIndex === 0) {
        this.payWithCard();
        return;
      }
      this.payWithACH();
    },
    handleExistingCardPayment() {
      this.isPaymentInProgress = true;
      this.getPaymentIntent(this.order.id).then((paymentIntent) => {
        this.stripe
          .confirmCardPayment(paymentIntent.client_secret, {
            payment_method: this.selectedPaymentMethod.id,
            return_url: `${window.location.origin}/${this.redirectUrl}`,
          })
          .then((result) => {
            if (result.error) {
              this.isPaymentInProgress = false;
              toast.error(result.error.message);
              return;
            }
            window.location.replace(
              this.createReturnUrl({
                order_id: this.order.id,
                payment_intent: result.paymentIntent.id,
              }),
            );
          });
      });
    },
    handleNewCardPayment() {
      this.isPaymentInProgress = true;
      this.stripe
        .confirmPayment({
          elements: this.stripeElements,
          confirmParams: {
            return_url: this.createReturnUrl({
              order_id: this.order.id,
            }),
          },
        })
        .then((result) => {
          if (result.error) {
            this.isPaymentInProgress = false;
            toast.error(result.error.message);
          }
        });
    },
    payWithCard() {
      if (this.isUsingExistingPaymentMethod) {
        this.handleExistingCardPayment();
      } else {
        this.handleNewCardPayment();
      }
    },
    async payWithACH() {
      if (this.isPaymentInProgress) {
        return;
      }
      let hasError = false;
      if (this.achRoutingNumber.value.length === 0) {
        this.achRoutingNumber.hasError = true;
        hasError = true;
      }
      if (this.achAccountNumber.value.length === 0) {
        this.achAccountNumber.hasError = true;
        hasError = true;
      }
      if (hasError) {
        return;
      }
      this.isPaymentInProgress = true;
      try {
        await this.requestACHPayment({
          orderId: this.order.id,
          paymentId: this.nextPayment.id,
          params: {
            ach_routing_number: this.achRoutingNumber.value,
            ach_account_number: this.achAccountNumber.value,
            confirm: 1,
          },
        });
        window.location.replace(
          this.createReturnUrl({ order_id: this.order.id }),
        );
      } catch (err) {
        console.log(err);
      }
      this.isPaymentInProgress = false;
    },
    close() {
      this.$emit('close');
    },
    selectDefaultPaymentMethodOption() {
      const option =
        this.paymentMethodsOptions.find((item) => item.default) ||
        this.paymentMethodsOptions[0];
      this.onPaymentMethodSelect(option);
    },
    createReturnUrl(query) {
      /**
       * Probably need to utilize https://stripe.com/docs/js/payment_intents/confirm_card_payment#stripe_confirm_card_payment-data-return_url
       */
      let url = `${window.location.origin}/${this.redirectAttrs.path}`;
      if (!_.isEmpty(query)) {
        const queryString = Object.keys(query)
          .map((key) => `${key}=${query[key]}`)
          .join('&');
        url = `${url}?${queryString}`;
      }
      return `${url}#${this.redirectAttrs.hash}`;
    },
  },
};
</script>
