<template>
  <div class="mt-[35px] rounded-[6px] border border-gpx_gray-800">
    <div
      class="px-[24px] pb-[32px] pt-[43px] sm:px-[40px] sm:pb-[40px] sm:pt-[51px]"
    >
      <!-- Rule Header -->
      <div class="mb-[30px] flex justify-between">
        <HeadingSection
          class="!mt-0"
          :heading="ruleName || 'Untitled rule'"
          desc="Choose rule settings"
        />
        <div class="flex items-center space-x-[12px]">
          <span class="text-gpx_sm font-medium text-lms_black-100">
            {{ enabled ? 'Enabled' : 'Disabled' }}
          </span>
          <UIToggle v-model="enabled" />
        </div>
      </div>
      <UIInput
        label="Rule name"
        placeholder="Untitled rule"
        v-model="ruleName"
      />
    </div>
    <!-- End Of Rule header -->

    <div
      class="flex flex-col gap-y-[33px] px-[24px] pb-[40px] pt-[33px] sm:gap-y-[41px] sm:px-[40px] sm:pb-[48px] sm:pt-[41px]"
    >
      <RulesAccordion heading="If">
        <template #content>
          <RulesItem
            v-for="(item, index) in ruleConditions"
            :key="item.id"
            :id="item.id"
            :value="item"
            :shouldShowOperator="index !== 0"
            @input="handleRuleConditionChange"
          />
        </template>
        <template #button>
          <RulesButton label="Add Condition" @click="addCondition" />
        </template>
      </RulesAccordion>
      <RulesAccordion heading="Then">
        <template #content>
          <RulesItem
            v-for="item in ruleActions"
            :key="item.id"
            :id="item.id"
            type="action"
            :value="item"
            @input="handleRuleActionChange"
          />
        </template>
        <template #button>
          <RulesButton label="Add Action" @click="addAction" />
        </template>
      </RulesAccordion>
    </div>
    <RulesFooter />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import HeadingSection from '../_common/Heading/HeadingSection.vue';
import RulesFooter from './RulesFooter.vue';
import RulesAccordion from './RulesAccordion.vue';
import RulesButton from './RulesButton.vue';
import RulesItem from './RulesItem.vue';
import { uuidv4 } from '@/helpers';
import { useToast } from 'vue-toastification';

export default {
  name: 'RuleForm',
  components: {
    HeadingSection,
    RulesFooter,
    RulesAccordion,
    RulesButton,
    RulesItem,
  },
  props: {
    ruleId: {
      type: Number,
      required: false,
    },
  },
  setup() {
    const toast = useToast();

    return { toast };
  },
  provide() {
    return {
      type: this.ruleId ? 'update' : 'create',
      saving: this.saving,
      deleting: this.deleting,
      saveRule: this.saveRule,
      deleteRule: this.deleteRule,
      deleteRuleItem: this.deleteRuleItem,
    };
  },
  data() {
    return {
      saving: false,
      deleting: false,
      ruleName: null,
      enabled: false,
      ruleConditions: [],
      ruleActions: [],
    };
  },

  computed: {
    ...mapGetters({
      ruleById: 'rule/byId',
      geofences: 'geofence/all',
      models: 'model/all',
      commands: 'command/all',
    }),
    rule() {
      return this.ruleById(this.ruleId);
    },
  },

  mounted() {
    if (this.ruleId) {
      this.fetchRuleById(this.ruleId);
    }

    if (!this.geofences?.length) {
      this.fetchGeofences();
    }

    if (!this.models?.length) {
      this.fetchModels();
    }

    if (!this.commands?.length) {
      this.fetchCommands();
    }
  },

  watch: {
    rule() {
      if (!this.rule) {
        return;
      }

      this.ruleName = this.rule.name;
      this.enabled = this.rule.is_enabled;
      this.ruleConditions = this.rule.conditions.map((condition) => ({
        ...condition,
        id: uuidv4(),
      }));
      this.ruleActions = this.rule.actions.map((action) => ({
        ...action,
        id: uuidv4(),
      }));
    },
  },

  methods: {
    ...mapActions({
      fetchGeofences: 'geofence/fetch',
      fetchModels: 'model/fetch',
      fetchCommands: 'command/fetch',
      createRule: 'rule/create',
      updateRule: 'rule/update',
      purgeRule: 'rule/delete',
      fetchRuleById: 'rule/fetchById',
    }),
    handleRuleConditionChange(rule) {
      this.ruleConditions = this.ruleConditions.map((condition) => {
        if (condition.id === rule.id) {
          return rule;
        }
        return condition;
      });
    },
    handleRuleActionChange(rule) {
      this.ruleActions = this.ruleActions.map((action) => {
        if (action.id === rule.id) {
          return rule;
        }
        return action;
      });
    },
    addCondition() {
      const id = uuidv4();
      this.ruleConditions.push({ id: id });
    },
    addAction() {
      const id = uuidv4();
      this.ruleActions.push({ id: id });
    },
    deleteRuleItem(type, id) {
      if (type === 'condition') {
        this.ruleConditions = this.ruleConditions.filter((e) => e.id !== id);
      }
      if (type === 'action') {
        this.ruleActions = this.ruleActions.filter((e) => e.id !== id);
      }
    },
    saveRule() {
      this.saving = true;
      let fn = this.createRule;
      if (this.rule) {
        fn = (params) => {
          return this.updateRule({ id: this.rule.id, params });
        };
      }

      fn({
        name: this.ruleName,
        is_enabled: this.enabled ? 1 : 0,
        conditions: this.ruleConditions,
        actions: this.ruleActions,
      })
        .then(() => {
          this.toast.success(
            `Rule "${this.ruleName}" has been saved successfully`,
          );
          this.$router.push('/rules');
        })
        .catch((e) => {
          this.toast.error(e.response?.data?.message || 'An error occurred');
        })
        .finally(() => {
          this.saving = false;
        });
    },
    deleteRule() {
      const rule = this.rule;
      this.deleting = true;
      this.purgeRule(rule.id)
        .then(() => {
          this.toast.warning(`Rule "${rule.name}" has been deleted`);
          this.$router.replace('/rules');
        })
        .catch((e) => {
          this.toast.error(e.response?.data?.message || 'An error occurred');
        })
        .finally(() => {
          this.deleting = false;
        });
    },
  },
};
</script>
