<template>
  <div
    class="pl-6 pr-4 sm:w-4/12 sm:border-r sm:border-lgmx_gray-100 md:pl-12 md:pr-6"
  >
    <DeviceDetailsNavBar class="h-[64px] sm:h-[48px] sm:translate-y-2" />
  </div>
  <div class="hidden justify-between px-4 py-2 sm:flex sm:w-8/12 md:pr-12">
    <div v-if="position" class="flex gap-2">
      <CommonRefreshIconButton
        :disabled="isLoading"
        :is-loading="isLoading"
        @click="forcedRefresh"
      />
      <VDatePickerRange
        v-model="dateModel"
        :maxDate="true"
        :initialValue="dateModel"
        tooltip="Select range"
        utc
      />
      <DeviceMapTracksLoader v-if="isLoading" />
    </div>
    <div v-if="position" class="flex justify-between gap-x-2">
      <CommonAutoRefreshButton
        :is-enabled="isEnabled"
        @enable="enable"
        @disable="disable"
      />
      <div class="hidden gap-x-2 sm:flex">
        <UITooltip text="Download stops">
          <CommonDownloadIconButton
            type="stops"
            :disabled="isDownloadStopsDisabled"
            @click="downloadStops"
          />
        </UITooltip>
        <UITooltip text="Download these points">
          <CommonDownloadIconButton
            type="positions"
            :disabled="isDownloadPointsDisabled"
            @click="downloadPoints"
          />
        </UITooltip>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onBeforeMount, unref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import NProgress from 'nprogress';
import { useToast } from 'vue-toastification';
import { useAutoRefresh } from '@/use/auto-refresh';
import CommonRefreshIconButton from '@/components/_common/CommonRefreshIconButton.vue';
import CommonDownloadIconButton from '@/components/_common/CommonDownloadIconButton.vue';
import DeviceMapTracksLoader from '../map/DeviceMapTracksLoader.vue';
import { getFileName } from '@/utils/blob';
import { isRouteForGpxAdmin } from '@/utils/rules';
import CommonAutoRefreshButton from '@/components/_common/CommonAutoRefreshButton.vue';
import DeviceDetailsNavBar from './DeviceDetailsNavBar.vue';
import { useDevice } from '@/composables/useDevice';
import { usePosition } from '@/composables/usePosition.ts';
import { storeToRefs } from 'pinia';
import { useFilter } from '@/composables/useFilter.ts';
import { usePositionHistory } from '@/composables/usePositionHistory.ts';
import { useStopHistory } from '@/composables/useStopHistory.ts';

const toast = useToast();
const store = useStore();
const route = useRoute();
const deviceStore = useDevice();
const filterStore = useFilter();
const positionHistory = usePositionHistory();
const stopHistory = useStopHistory();

const {
  getById,
  fetchByAccountId,
  fetchByAccountIdAndId,
  downloadByAccountId,
} = useDevice();
const { getPositionByDeviceId, fetchPositionsByAccount } = usePosition();
const { deviceView } = storeToRefs(deviceStore);

const {
  fetchHistoryPositionsByAccountAndDevice,
  getHistoryPositionsByDateForDeviceId,
} = positionHistory;
const {
  fetchStopHistoryByAccountAndDevice,
  downloadAll: downloadStopHistory,
  getStopHistoryByDateForDeviceId,
} = stopHistory;

const { setDateFilter } = filterStore;
const { dateFilter } = storeToRefs(filterStore);

const isLoading = ref(false);

const dateModel = ref(
  unref(dateFilter).dateFrom
    ? [unref(dateFilter).dateFrom, unref(dateFilter).dateTo]
    : null,
);

const isGpxAdminPage = isRouteForGpxAdmin(route);
const accountId = isGpxAdminPage
  ? +route.params.accountId
  : store.getters['auth/accountId'];

const canSeeCancelledDevices = store.getters['auth/canSeeCancelledDevices'];

const deviceId = +route.params.id;

const device = computed(() => {
  let foundDevice = getById(deviceId);
  if (foundDevice) {
    return foundDevice;
  }

  if (!foundDevice && deviceView.value?.id === deviceId) {
    return deviceView.value;
  }

  return null;
});

let position = null;
onBeforeMount(async () => {
  position = getPositionByDeviceId(deviceId);
  if (!device.value) {
    await fetchByAccountIdAndId(accountId, deviceId);
  }

  isLoading.value = false;
});

const isPeriodInFuture = computed(() => {
  return new Date(unref(dateFilter).dateTo) > new Date();
});

const isDownloadPointsDisabled = computed(
  () =>
    !getStopHistoryByDateForDeviceId(
      deviceId,
      dateFilter.value.dateFrom,
      dateFilter.value.dateTo,
    ),
);
const isDownloadStopsDisabled = computed(
  () =>
    !getHistoryPositionsByDateForDeviceId(
      deviceId,
      dateFilter.value.dateFrom,
      dateFilter.value.dateTo,
    ),
);

const fetchDevices = () => {
  const defaultStatuses = 'active,pending,pending-renewal,pending-cancellation';
  const subscriptionStatuses = canSeeCancelledDevices
    ? defaultStatuses.concat(',', 'cancelled,blocked')
    : defaultStatuses;

  return fetchByAccountId(accountId, {
    with_current_position: true,
    subscription_status: subscriptionStatuses,
  });
};

const fetchHistory = () => {
  return Promise.all([
    fetchHistoryPositionsByAccountAndDevice(accountId, deviceId),
    fetchStopHistoryByAccountAndDevice(accountId, deviceId),
  ]);
};

const downloadPoints = () => {
  let fields = [
    'device.name',
    'device.serial',
    'address',
    'fix_time',
    'lat',
    'lng',
    'mph',
    'odometer',
    'map',
    'battery',
  ];

  if (
    device.value?.model?.has_temperature ||
    device.value?.model?.has_humidity
  ) {
    fields = [
      ...fields,
      'device.triggers.min_humidity',
      'device.triggers.max_humidity',
      'device.triggers.min_temperature',
      'device.triggers.max_temperature',
      'temperature',
      'humidity',
    ];
  }

  const filters = [
    {
      field: 'account_id',
      value: accountId,
    },
    {
      field: 'fix_time_from',
      value: unref(dateFilter).dateFrom,
    },
    {
      field: 'fix_time_to',
      value: unref(dateFilter).dateTo,
    },
    {
      field: 'offset',
      value: new Date().getTimezoneOffset(),
    },
    {
      field: 'device',
      conditions: [
        {
          field: 'id',
          value: [deviceId],
        },
      ],
    },
  ];

  const fileName = getFileName({
    prefix: `positions_${device.value.name}`,
    dateFrom: unref(dateFilter).dateFrom,
    dateTo: unref(dateFilter).dateTo,
  });

  downloadByAccountId(fileName, {
    fields,
    filters,
  });
};

const downloadStops = () => {
  downloadStopHistory(
    accountId,
    deviceId,
    getFileName({
      prefix: `stops_${device.value.name}`,
      dateFrom: unref(dateFilter).dateFrom,
      dateTo: unref(dateFilter).dateTo,
    }),
    {
      download: true,
      offset: new Date().getTimezoneOffset(),
      date_from: unref(dateFilter).dateFrom,
      date_to: unref(dateFilter).dateTo,
    },
  );
};

const refreshAll = () => {
  isLoading.value = true;
  NProgress.start();

  const refreshFns = [fetchDevices(), fetchPositionsByAccount(accountId)];
  if (isPeriodInFuture.value) {
    refreshFns.push(fetchHistory());
  }

  const promise = Promise.all(refreshFns);
  promise.finally(() => {
    isLoading.value = false;
    NProgress.done();
  });
  return promise;
};

const { enable, disable, pause, resume, isEnabled, forcedRefresh } =
  useAutoRefresh(refreshAll);

watch(
  dateModel,
  (n) => {
    if (!n) return;
    if (isEnabled.value) {
      pause();
    }
    const [dateFrom, dateTo] = n;
    setDateFilter(dateFrom, dateTo);
    if (
      getHistoryPositionsByDateForDeviceId(
        deviceId,
        dateFilter.value.dateFrom,
        dateFilter.value.dateTo,
      ) &&
      !isPeriodInFuture.value
    )
      return;

    isLoading.value = true;
    fetchHistory()
      .then((res) => {
        if (res.every((r) => r.status === 200)) isLoading.value = false;
      })
      .catch((e) => {
        toast.error(e.response?.data?.message || 'An error occurred');
        isLoading.value = false;
      })
      .finally(() => {
        if (isEnabled.value) {
          resume();
          isLoading.value = false;
        }
      });
  },
  { immediate: true },
);
</script>
