<template>
  <div class="flex flex-col gap-y-2">
    <label v-if="label">{{ label }}</label>
    <input
      :id="id"
      :disabled="disabled"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :type="type"
      :step="step"
      :class="inputClass"
      :readonly="readOnly"
      :placeholder="placeholder"
      :v-maska="vMaska"
      :data-maska="dataMaska"
    />
  </div>
</template>

<script>
export default {
  emits: ['update:modelValue'],
  props: [
    'id',
    'disabled',
    'modelValue',
    'type',
    'step',
    'label',
    'placeholder',
    'size',
    'readOnly',
    'vMaska',
    'dataMaska',
  ],

  computed: {
    inputClass() {
      return `${this.type} ${this.size}`;
    },
  },
};
</script>

<style scoped>
.text,
.tel,
.email,
.search,
.password,
.url,
.date {
  @apply text-ellipsis px-4 py-1 h-10 rounded-md border-gray-600 text-base text-gray-900 placeholder-gray-750 w-full shadow-inner transition-all outline-none focus:ring-red-400/25 focus:ring-3 focus:border-red-250 disabled:!border-gray-600 disabled:!border disabled:!shadow-inner disabled:!px-4 disabled:cursor-not-allowed;
}
.text:read-only,
.tel:read-only,
.email:read-only,
.search:read-only,
.password:read-only,
.url:read-only,
.date:read-only {
  @apply border-0 px-0 shadow-none focus:ring-inset focus:ring-2 focus:ring-[#025ecc];
}
.sm {
  @apply px-2 h-8 rounded-[3px] text-sm disabled:!px-2;
}
</style>
